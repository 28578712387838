@import './default.scss'; // clear default browaer styles and set custom rules for some DOM elements
@import './fonts.scss'; // inporting fonts // PrStart, prstartk and Poppins
// @import '~locomotive-scroll/src/locomotive-scroll.scss';

/* Variables */
$body-color: #1C1C28;
$primary-orange: #FF8616;
$white-color: #fff;
$white-blue: #DEE1FF;
$green-color: #21AA64;
$lite-green-color: #76FF0A;
$red-color: #FF3C3C;
$blue-color: #0F7DFF;
$dark-blue-color: #746AAD;
$primary-font: 'Poppins';
$secondary-font: 'PrStart';
$thirty-font: 'prstartk';

/* Screen backgrounds */
$header-screen-image: '../images/header_screen.jpg';
$about-screen-image: '../images/about_us_screen.jpg';
$gameplay-screen-image: '../images/gameplay_screen.jpg';
$footer-screen-image: '../images/footer-screen.jpg';
/* //END// of screen backgrounds */

// icons
$arrow_down_svg: '../images/arrow_downward.svg';
$slashes_ico: '../images/slashes.svg';
$nft_coin: '../images/nft-coin.svg';
$bitcoin: '../images/bitcoin.svg';
$binance_coin: '../images/binance-coin.png';
$allcoin: '../images/allcoin.svg';
$copy_ico: '../images/copy-ico.svg';
$copy_ico_green: '../images/copy-ico-green.svg';
$telegram_ico: '../images/telegram-ico.svg';
$twitter_ico: '../images/twitter-ico.svg';
$bscscan_ico: '../images/bscscan-ico.png';
$etherscan_ico: '../images/etherscan-ico.png';
$github_ico: '../images/github-ico.svg';
$medium_ico: '../images/medium-ico.svg';
$green_bound_ico: '../images/green-bound-ico.svg';
$violet_left_top_bound_ico: '../images/violet-left-top-bound-ico.svg';
$violet_right_top_bound_ico: '../images/violet-right-top-bound-ico.svg';
$violet_bound_ico: '../images/violet-bound-ico.svg';
$red_bound_ico: '../images/red-bound-ico.svg';
$arrow_right_ico: '../images/arrow-right.svg';
$mobile_menu_ico: '../images/mobile-menu-ico.svg';
$mobile_menu_close_ico: '../images/close-ico.svg';
$mobile_backdrop_mobile: '../images/bg-test.png';
$us_flag_ico: '../images/us-flag.svg';
$cn_flag_ico: '../images/cn-flag.svg';
/* //END// of Variables */

/* Dekor Icons */
$circle_half_ico: '../images/ellipse-half-ico.png';
$eye_ico: '../images/eye-ico.png';
$ellipse_ico: '../images/ellipse-ico.png';
$ellipse_2_ico: '../images/ellipse-2-ico.png';
$small_ellipse_ico: '../images/small-ellipse-ico.png';
$small_ellipse_2_ico: '../images/small-ellipse-2-ico.png';
$star_half_ico: '../images/star-ico.png';
$star_ico: '../images/star-ico.svg';
$rectangle_ico: '../images/rectangle-ico.png';
$rectangle_2_ico: '../images/rectangle-2-ico.png';
$warior_character_ico: '../images/warior-character-ico.png';
$dark_king_character_ico: '../images/dark-king-character-ico.png';
$dark_king_character_2_ico: '../images/dark-king-character-2-ico.png';
$skeleton_character_ico: '../images/skeleton-character-ico.png';
$businessman_character_ico: '../images/businessman-character-ico.png';
$eye_and_eclipse_ico: '../images/eye-and-eclipse-ico.png';
$eye_and_eclipse_2_ico: '../images/eye-and-eclipse-2-ico.png';
$eye_and_eclipse_3_ico: '../images/eye-and-eclipse-3-ico.png';
$stretch_rectangle_horizontal_ico: '../images/stretch-rectangle-horizontal-ico.png';
$stretch_rectangle_vertical_ico: '../images/stretch-rectangle-vertical-ico.png';
$small_star_ico: '../images/small-star-ico.png';
$medium_ellipse_ico: '../images/medium-ellipse-ico.png';
$bat_character_ico: '../images/bat-character-ico.png';
$death_character_ico: '../images/death-character-ico.png';
$small_star_thin_ico: '../images/small-star-thin-ico.png';
$dower_chest_ico: '../images/dower-chest-ico.png';
/* //END// of dekor Icons */


/* Mixins */
@mixin flex {
  display: flex;
  display: -webkit-flex;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}
/* //END// of mixins */

/* Common Styles */
body{
	color: $white-color;
	background: $body-color;
	font-family: $primary-font;
}
body.opened_mobile_menu{
	overflow-y: hidden;
}

body[style="overflow-y: hidden;"]{
	border-right: 5px solid black;
}

.container{
	max-width: 1090px;
	margin: 0 auto;
	padding: 0 20px;
}

.dekor, .dekor-wrap{
	position: absolute;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	// transition: all 0.2s cubic-bezier(0, 1.22, 0.44, 1.67);
	// z-index: -2!important;
}
// .bg-wrap{
// 	transition: all 0.2s cubic-bezier(0, 1.22, 0.44, 1.67);
// }
.screen{
	@include flex;
	flex-direction: column;
	
	.general_title{
		font-family: $thirty-font;

		h2{
			font-family: $thirty-font;
			color: $white-color;
		}
	}
}

.screen100vh{
	height: 100vh;
}

.opened_mobile_menu{
	.screen{
		filter: blur(25px);
	}
}

i.copyrights{
	font-family: sans-serif;
	font-style: normal;
	margin: 0 15px;
}

.button-bar{
	@include flex;
	justify-content: center;
	flex-wrap: wrap;

	.primary-btn{
		display: inline-block;
		color: $white-color;
		padding: 18.5px 40px;
		background: linear-gradient(267.28deg, #7B61FF 24%, #AA82FF 73.95%);
		@include border-radius(100px);
		box-shadow: 6px 5px 18px rgba(0, 0, 0, 0.25);
		backdrop-filter: blur(4px);
		font-family: $thirty-font;
		font-weight: 400;
		font-size: 20px;
		line-height: 20px;

		@media screen and ( max-width: 768px ){
			&{
				font-size: 14px;
				line-height: 14px;
				font-weight: 400;
				padding: 16px 24px;
				margin-top: 24px;
				min-width: 244px;
				text-align: center;
			}
		}
	}
	.liner-button{
		font-weight: 600;
		font-size: 18px;
		line-height: 27px;
		color: $white-color;
		margin-top: 56px;
	}
	.liner-button.arrow-ico{
		@include flex;

		@media screen and ( max-width: 768px ){
			&{
				margin-top: 30px;
			}
		}
	}
	.liner-button.arrow-ico:after{
		content: '';
		display: block;
		width: 16px;
		height: 24px;
		background-image: url($arrow_down_svg);
		margin-left: 4px;
	}
}

.container{
	@include flex;
	flex-direction: column;
	// flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;

	.row{
		width: 100%;
	}
	>.title, > .title h2{
		font-size: 32px;
		line-height: 40px;
		font-weight: 400;
		text-align: center;
		color: $white-color;
		margin-top: 16px;

		@media screen and ( max-width: 768px ){
			&{
				font-size: 20px;
				line-height: 40px;
				font-weight: 400;
				margin-top: 8px;
			}
		}
	}
	.title h2{
		margin-top: 0;
		margin-bottom: 0;
	}
	.pretitle{
		@include flex;
		justify-content: center;
		align-items: center;
		color: $primary-orange;
		font-size: 14px;
		line-height: 13px;
		font-weight: 700;

		@media screen and ( max-width: 768px ){
			&{
				font-size: 12px;
				line-height: 11px;
			}
		}

		&:before{
			content: '';
			background-color: $primary-orange;
			width: 40px;
			height: 2px;
			margin-right: 8px;
		}
		&:after{
			content: '';
			background-color: $primary-orange;
			width: 40px;
			height: 2px;
			margin-left: 8px;
		}
	}
	.subtitle{
		font-family: $thirty-font;
		font-style: normal;
		font-weight: 400;
		font-size: 24px;
		line-height: 40px;
		margin-top: 56px;
		color: $white-color;

		h3{
			@extend .subtitle;
			margin-top: 0;

			@media screen and ( max-width: 768px ){
				&{
					font-size: 14px;
					line-height: 40px!important;
					font-weight: 400;
				}
			}
		}
	}
}
.col-1{
	>*{
		flex: 0 1 100%
	}
}
.col-2{
	>*{
		flex: 0 1 50%
	}
}
.col-3{
	>*{
		flex: 0 1 33.333%
	}
}
.col-4{
	>*{
		flex: 0 1 25%
	}
}
/* //END// of Common Styles */

/* Social bar (fixed) */
.social-bar{
	position: fixed;
	width: 80px;
	box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.1);
	@include border-radius(18px);
	z-index: 5;
	left: 88px;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	overflow: hidden;

	@media screen and ( max-width: 768px ){
		&{
			width: auto;
			position: absolute;
			// top: 120px;
			top: 185px;
			left: 20px;
			-webkit-transform: unset;
			-moz-transform: unset;
			-o-transform: unset;
			transform: unset;
		}
	}

	.bg{
		background: linear-gradient(106.41deg, rgba(255, 255, 255, 0.18) 7.3%, rgba(255, 255, 255, 0.06) 100%)!important;
		backdrop-filter: blur(40px);
	}

	.inner{
		position: relative;
		padding: 24px 20px;

		@media screen and ( max-width: 768px ){
			&{
				padding: 10px;
			}
		}

		.link{
			display: block;
			width: 40px;
			height: 40px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			transition: all .2s ease-in-out;
			margin: 0 auto;
			margin: 19px 0;
			outline: none;

				@media screen and ( max-width: 768px ){
					&{
						width: 32px;
						height: 32px;
					}
				}

				&:first-child{
					margin-top: 0;
				}

				&.telegram{
					background-image: url($telegram_ico);
				}
				&.twitter{
					background-image: url($twitter_ico);
				}
				&.bscscan{
					background-image: url($bscscan_ico);
				}
				&.etherscan{
					background-image: url($etherscan_ico);
				}
				&.github{
					background-image: url($github_ico);
				}
				&.medium{
					background-image: url($medium_ico);
				}

				&:last-child{
					margin-bottom: 0;
				}
	
				&:hover{
					-webkit-transform: scale(1.15);
					-moz-transform: scale(1.15);
					-o-transform: scale(1.15);
					transform: scale(1.15);
				}
		}
	}
}
/* //END// of social bar (fixed) */

/* Header Screen */
.header-screen{
	position: relative;
	background: url($header-screen-image);
	background-position: center 5%;
	background-size: cover;
	background-repeat: no-repeat;

	@media screen and ( max-width: 768px ){
		&{
			background-position: 53% 5%;
		}
	}

	.container{
		justify-content: flex-start;
		margin-bottom: 168px;
		margin-bottom: 43px;
		
		@media screen and ( max-width: 768px ){
			&{
				margin-bottom: 10px;
			}
		}
	}
	.logo{
		display: block;
		margin: 127px auto 0 auto;
		image-rendering: pixelated;

		@media screen and ( max-width: 768px ){
			&{
				width: 179px;
				height: 237px;
			}
		}
	}
	.title{
		margin-top: 16px;
		color: $white-color;
		font-weight: 700;
		font-size: 48px;
		line-height: 72px;
		text-align: center;
		letter-spacing: -0.02em;
		margin-bottom: 24px;

		@media screen and ( max-width: 768px ){
			&{
				font-size: 24px;
				line-height: 36px;
				font-weight: 700;
			}
		}
	}
	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(180deg, rgba(28, 28, 40, 0.8) 0%, rgba(28, 28, 40, 0) 77.78%),linear-gradient(360deg, #1C1C28 0%, rgba(28, 28, 40, 0) 77.78%);
	}
	.dekor-circle{
		width: 175px;
		height: 228px;
		right: 0;
		bottom: 84px;
		background-image: url($circle_half_ico);

		@media screen and ( max-width: 768px ){
			&{
				display: none;
			}
		}
	}
	.dekor-eye{
		width: 55px;
		height: 55px;
		right: 108px;
		bottom: 84px;
		background-image: url($eye_ico);

		@media screen and ( max-width: 768px ){
			&{
				display: none;
			}
		}
	}
	.dekor-star{
		width: 36px;
		height: 55px;
		right: 0;
		bottom: 84px;
		background-image: url($star_half_ico);

		@media screen and ( max-width: 768px ){
			&{
				display: none;
			}
		}
	}
}

header{
	position: relative;
	@include flex;
	justify-content: space-between;
	padding: 40px;
	z-index: 6;

	.mobile-toggle-menu{
		display: none;
	}

	.mobile-bg{
		display: none;
	}

	nav{
		margin-left: auto;
		margin-right: auto;
		-webkit-transform: translateX(43.5px);
		-moz-transform: translateX(43.5px);
		-o-transform: translateX(43.5px);
		transform: translateX(43.5px);

		.social-bar{
			display: none;
		}
	}
	
	@media screen and ( max-width: 768px ){
		&{
			@include flex;
			justify-content: flex-end;
			padding: 45px 20px 20px 20px;
		}
		nav{
			position: relative;
			align-items: center;
			height: 100%;
			justify-content: flex-start;
			text-align: center;
			display: none;
			position: fixed;
			top: 0;
			width: 100%;
			left: 0;
			@include border-radius(0 0 60px 60px);
			overflow: hidden;
			z-index: 6;
			padding-top: 70px;
			-webkit-transform: unset;
			-moz-transform: unset;
			-o-transform: unset;
			transform: unset;

			&.show{
				@include flex;
				flex-direction: column;
			}
					
			ul{
				position: relative;
				flex-direction: column;
				z-index: 7;

				li{
					margin: 30px 0;

					&:first-child{
						margin-top: 0;
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}

			.mobile-bg{
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				top: -10%;
				left: 0;
				background: #fff;
				background: linear-gradient(106.41deg, rgba(173, 173, 173, 0.09) 7.3%, rgba(24, 24, 24, 0.21) 100%);
				// backdrop-filter: blur(115px);
				z-index: 6;
				@include border-radius(0 0 60px 60px);
				box-shadow: 2px 5px 45px rgb(0 0 0 / 80%);
				overflow: hidden;

				&:after{
					content: '';
					position: absolute;
					display: block;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
				}
			}
			.close-overlay{
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				top: 0%;
				left: 0;
			}

			.social-bar{
				display: block;
				position: static;
				margin-top: 70px;
				box-shadow: unset;
				z-index: 7;

				.inner{
					@include flex;

					.link{
						margin: 0 8px;

						&:first-child{
							margin-left: 0;
						}
						&:last-child{
							margin-right: 0;
						}
					}
				}
			}
		}
		.mobile-toggle-menu{
			position: fixed;
			display: block;
			width: 32px;
			height: 32px;
			background-image: url($mobile_menu_ico);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			background-color: transparent;
			border: 0;
			margin-top: -18px;
			z-index: 7;

			&.show{
				background-image: url($mobile_menu_close_ico);
			}
		}
	}

	ul{
		@include flex;
		justify-content: center;
		list-style: none;
		margin: 0;
		padding: 0;

		li{
			a{
				color: $white-color;
				margin: 0 20px;
				font-size: 20px;
				line-height: 30px;
				font-weight: 700;
	
				&:hover, &:active, &:focus{
					color: $white-color;
				}
			}
		}
	}

	// languages switcher
	.languages_switcher{
		@media screen and ( max-width: 768px ){
			position: absolute;
			left: 20px;
			top: 28px;
			z-index: 6;
		}

		.inner{
			position: relative;
			@include flex;
			align-items: center;
			padding: 5px;
			min-height: 42px;
			@include border-radius(32px);
			overflow: hidden;

			.lang{
				display: block;
				width: 25px;
				height: 25px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				outline: none;
				@include border-radius(100%);
				overflow: hidden;
				z-index: 1;

				&.active{
					width: 32px;
					height: 32px;
					border: 1px solid $green-color;
				}

				&.us{
					background-image: url($us_flag_ico);
					margin-right: 10px;
				}

				&.cn{
					background-image: url($cn_flag_ico);
					margin-left: 10px;
				}
			}

			.circle-dekor{
				position: absolute;
				background-color: $green-color;
				width: 33px;
				height: 33px;
				top: -10px;
				left: -10px;
				filter: blur(19.5px);
			}

			.bg{
				position: absolute;
				background: linear-gradient(106.41deg, rgba(173, 173, 173, 0.09) 7.3%, rgba(24, 24, 24, 0.21) 100%);
				backdrop-filter: blur(57.5px);
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				z-index: -1;
			}
		}
	}
}
/* //END// of Header Screen */

/* About Screen */
.about_us-screen{
	position: relative;

	.container{
		padding-top: 194px;
		padding-bottom: 221px;
		max-width: 1114px;

		@media screen and ( max-width: 768px ){
			&{
				padding: 164px 20px 200px 20px;
			}
		}
	}
	.row{
		margin: 0 -12px;
	}

	.bg-wrap{
		position: absolute;
		width: 100%;
		height: 100%;

		.bg{
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background: linear-gradient(180deg, #1C1C28 0%, rgba(28, 28, 40, 0) 77.78%), linear-gradient(360deg, #1C1C28 3%, rgba(28, 28, 40, 0) 77.78%), url($about-screen-image);
			background-position: center 3%;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

	.col-2{
		@include flex;

		>*{
			margin: 0 12px;
		}
	}
	.snippets{
		margin-top: 32px;

		@media screen and ( max-width: 768px ){
			&{
				flex-direction: column;
			}
		}

		.snippet-item{
			position: relative;
			@include flex();
			// @include border-radius(16px);
	
			@media screen and ( max-width: 768px ){
				&{
					margin: 0;
					margin-bottom: 24px;
					flex: 0 1 100%;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}

			&:nth-child(1){
				.dekor-worrior-2-character{
					display: none;
	
					@media screen and ( max-width: 768px ){
						&{
							display: block;
						}
					}
				}
			}

			&:nth-child(2){
				@media screen and ( max-width: 768px ){
					.dekor-worrior-2-character{
						display: none;
					}
				}
			}

			>.inner{
				position: relative;
				@include border-radius(16px);
				// height: 100%;
				overflow: hidden;

				&:before{
					position: relative;
					content: '';
					display: block;
					background-color: $dark-blue-color;
					// width: 100%;
					height: 10px;
					// @include border-radius(16px);
					// z-index: -1;
				}
				.content{
					position: relative;

					.title{
						position: relative;
						@include flex;
						font-weight: 700;
						font-size: 14px;
						line-height: 21px;
						color: $white-blue;
					}
					.description{
						padding: 18px 24px 24px 24px;
						font-weight: 400;
						font-size: 14px;
						line-height: 21px;
						color: $white-blue;
	
						@media screen and ( max-width: 768px ){
							&{
								padding: 8px;
							}
						}

						p{
							padding: 18px 24px 24px 24px;
							font-weight: 400;
							font-size: 14px;
							line-height: 21px;
							color: $white-blue;
							padding: 0;

							&:last-child{
								margin-bottom: 0;
							}
						}
					}
					h3{
						position: relative;
						@include flex;
						font-weight: 700;
						font-size: 14px;
						line-height: 21px;
						color: $white-blue;
						margin-bottom: 0;
						padding: 6.5px 24px;
						background: linear-gradient(257deg, transparent 15px, #57576A 0);
						min-width: 220px;

				    &:after{
							content: "";
					    display: block;
					    background-image: url($slashes_ico);
					    background-repeat: no-repeat;
					    background-position: center;
					    background-size: contain;
					    width: 30px;
					    position: absolute;
					    height: 34px;
					    top: 0;
					    right: -19px;
				    }
					}
				}
			}

			.bg{
				position: absolute;
				width: calc(100% + 32px);
				height: 100%;
				left: -16px;
			  	right: -16px;
				top: 0;
				background: rgba(32, 32, 46, 0.8);
				@include border-radius(32px 32px 0 0);
				backdrop-filter: blur(15px);
				// z-index: -1;
				margin-top: 5px;
			}
			figure{
				position: relative;
				padding: 14px 24px 24px 24px;
	
				@media screen and ( max-width: 768px ){
					&{
						padding: 3px 8px 8px 8px;
					}
				}

				img{
					@include border-radius(16px);
					overflow: hidden;
				}

				.icon{
					position: absolute;
					width: 57px;
					height: 57px;
					left: 24px;
					bottom: 24px;
				}
			}
		}
		.snippet-item{
			&:nth-child(even){
				>.inner{
					&:before{
						background-color: $primary-orange;
					}
				}
			}
		}
	}

	/* Decorations */
		.dekor-star{
			width: 59px;
			height: 59px;
			left: -30px;
			top: 0;
			background-image: url($star_ico);

			@media screen and ( max-width: 768px ){
				&{
					width: 40px;
					height: 40px;
					left: 60px;
					top: 97px;
				}
			}
		}
		.dekor-rectangle{
			width: 76px;
			height: 27px;
			right: 0;
			top: 0;
			background-image: url($rectangle_ico);
		}
		.dekor-worrior-character{
			width: 131px;
			height: 131px;
			left: 0;
			z-index: 2;
			top: -110px;
			background-size: cover;
			background-position: 0 100%;
			background-image: url($dark_king_character_ico);

			&.move{
				background-position: 100% 100%;
			}

			@media screen and ( max-width: 768px ){
				&{
					width: 59px;
					height: 59px;
					top: -50px;
					left: -8px;
				}
			}
		}
		.dekor-worrior-2-character{
			width: 175px;
			height: 168px;
			right: -27px;
			z-index: 2;
			top: -128px;
			background-size: cover;
			background-position: 0 100%;
			background-image: url($dark_king_character_2_ico);

			&.move{
				background-position: 100% 100%;
			}
	
			@media screen and ( max-width: 768px ){
				&{
					width: 86px;
					height: 82px;
					top: -61px;
					right: -10px;
				}
			}
		}
		.dekor-ellipse-ico{
			width: 225px;
			height: 225px;
			right: -94px;
			top: -117px;
			z-index: -1;
			background-image: url($ellipse_ico);
	
			@media screen and ( max-width: 768px ){
				&{
					top: -228px;
					z-index: -2;
				}
			}
		}
		.dekor-star-2{
			width: 59px;
			height: 59px;
			right: -25px;
			top: 128px;
			background-image: url($star_ico);
	
			@media screen and ( max-width: 768px ){
				&{
					display: none;
				}
			}
		}
		.dekor-worrior-rectangle-2{
			width: 76px;
			height: 27px;
			left: 0;
			z-index: -1;
			top: -130px;
			background-image: url($rectangle_2_ico);

			@media screen and ( max-width: 768px ){
				&{
					left: -32px;
					top: -63px;
				}
			}
		}
		.dekor-eclipse-2{
			width: 110px;
			height: 110px;
			left: -25px;
			z-index: -1;
			top: 125px;
			background-image: url($ellipse_2_ico);

			@media screen and ( max-width: 768px ){
				&{
					left: -44px;
					top: 293px;
				}
			}
		}
		.dekor-eye-eclipse{
			width: 70px;
			height: 70px;
			margin-left: -354px;
			-webkit-transform: translateX(50%);
			-moz-transform: translateX(50%);
			-o-transform: translateX(50%);
			transform: translateX(50%);
			bottom: -20px;
			background-image: url($eye_and_eclipse_ico);

			@media screen and ( max-width: 768px ){
				&{
					width: 65px;
					height: 65px;
					margin-left: 0;
					-webkit-transform: unset;
					-moz-transform: unset;
					-o-transform: unset;
					transform: unset;
					left: 25px;
					bottom: -27px;
				}
			}
		}
	/* //END// of decorations */
}
/* //END// of About Screen */

/* NFT Collection Screen */
.nft-screen{
	position: relative;
	// padding-top: 10px;
	padding-bottom: 108px;
	background-color: $body-color;

	@media screen and ( max-width: 768px ){
		&{
			padding-bottom: 50px;
		}
		>.row{
			margin-top: -40px;
			z-index: 2;
		}
		.container{
			.subtitle{
				margin-top: 14px;

				h3{
					margin: 0;
				}
			}
		}
	}

	.container{
		height: auto;
	}
	.row{
		position: relative;
		
		.bg{
			position: absolute;
			width: 100%;
			height: 100%;
			background: linear-gradient(270deg, rgba(217, 217, 217, 0) -6.46%, rgba(255, 255, 255, 0.12) 54.08%, rgba(255, 255, 255, 0) 107.5%);
			opacity: 0.5;
		}
	}

	.content-wrap{
		@include flex;
		justify-content: flex-end;
		margin: 10px auto 0 auto;

		.content{
			position: relative;
			font-family: $thirty-font;
			font-size: 14px;
			line-height: 40px;
			font-weight: 400;
			min-width: 247px;
			box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.1);
			border-radius: 15px;
			overflow: hidden;
			padding: 10px;

			@media screen and ( max-width: 768px ){
				&{
					min-width: 186px;
					font-size: 12px;
					line-height: 34px;
				}
			}

			&:before{
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: linear-gradient(106.41deg, rgba(255, 255, 255, 0.18) 7.3%, rgba(255, 255, 255, 0.06) 100%);
				backdrop-filter: blur(40px);
				opacity: 0.5;
			}
			
			.item{
				@include flex;
				justify-content: space-between;
				position: relative;

				&:nth-child(1){
					>span{
						&:last-child{
							color: $red-color
						}
					}
				}
				&:nth-child(2){
					>span{
						&:last-child{
							color: $lite-green-color
						}
					}
				}
				&:nth-child(3){
					>span{
						&:last-child{
							color: $blue-color
						}
					}
				}
			}
		}
	}

	// carusel
	.nft-carusel{
		@media screen and ( max-width: 768px ){
			&{
				padding-top: 6px;
			}
		}
		.swiper-wrapper{
			height: auto;
			padding: 46px 0;

			@media screen and ( max-width: 768px ){
				&{
					padding: 57px 0 46px 0;
				}
			}
		}
		.swiper-slide:not(.swiper-slide-active) img{
			box-shadow: unset!important
		}
		.swiper-slide{
			text-align: center;
			height: auto;
			cursor: pointer;
			@include flex;
			align-items: center;
			justify-content: center;
			// width: 190px;
			// height: 190px;

			.tilt{
				will-change: transform;
				-webkit-transform: perspective(300px) rotateX(0deg) rotateY(0deg);
				-moz-transform: perspective(300px) rotateX(0deg) rotateY(0deg);
				-o-transform: perspective(300px) rotateX(0deg) rotateY(0deg);
				transform: perspective(300px) rotateX(0deg) rotateY(0deg);

				@media screen and ( max-width: 768px ){
					&{
						-webkit-transform: perspective(300px) rotateX(0deg) rotateY(0deg)!important;
						-moz-transform: perspective(300px) rotateX(0deg) rotateY(0deg)!important;
						-o-transform: perspective(300px) rotateX(0deg) rotateY(0deg)!important;
						transform: perspective(300px) rotateX(0deg) rotateY(0deg)!important;
					}
				}

				&:not(:hover){
					-webkit-transition: width .2s, height .2s, background-color .2s, -webkit-transform .2s;
					-moz-transition: width .2s, height .2s, background-color .2s, -moz-transform .2s;
					-o-transition: width .2s, height .2s, background-color .2s, -o-transform .2s;
					transition: width .2s, height .2s, background-color .2s, transform .2s;
				}

				img{
					-webkit-transition: width .2s, height .2s, background-color .2s, -webkit-transform .2s;
					-moz-transition: width .2s, height .2s, background-color .2s, -moz-transform .2s;
					-o-transition: width .2s, height .2s, background-color .2s, -o-transform .2s;
					transition: width .2s, height .2s, background-color .2s, transform .2s;
				}
			}

			img{
				// width: 190px;
				// height: 190px;
				@include border-radius(15px);

				@media screen and ( max-width: 768px ){
					&{
						width: auto;
						height: auto;
					}
				}
			}

			&:not(&-active){
				@media screen and ( max-width: 768px ){
					&{
						width: 80px;
					}
				}

				.tilt{
					&:hover{
						img{
							-webkit-transform: scale(1.07);
							-moz-transform: scale(1.07);
							-o-transform: scale(1.07);
							transform: scale(1.07);
						}
					}
				}
			}
			&.before-active{
				-webkit-transform:translateX(-14%);
				-moz-transform:translateX(-14%);
				-o-transform:translateX(-14%);
				transform:translateX(-14%);

				@media screen and ( max-width: 768px ){
					&{
						-webkit-transform:translateX(-30.85%);
						-moz-transform:translateX(-30.85%);
						-o-transform:translateX(-30.85%);
						transform:translateX(-30.85%);
					}
				}
			}
			&.after-active{
				-webkit-transform:translateX(14%);
				-moz-transform:translateX(14%);
				-o-transform:translateX(14%);
				transform:translateX(14%);

				@media screen and ( max-width: 768px ){
					&{
						-webkit-transform:translateX(30.85%);
						-moz-transform:translateX(30.85%);
						-o-transform:translateX(30.85%);
						transform:translateX(30.85%);
					}
				}
			}
			&-active{
				// width: 250px;
				// height: 250px;
				
				img{
					-webkit-transform: scale(1.28);
					-moz-transform: scale(1.28);
					-o-transform: scale(1.28);
					transform: scale(1.28);

					@media screen and ( max-width: 768px ){
						&{
							width: auto;
							// min-width: 120px;
							height: auto;
							-webkit-transform: scale(1.617);
							-moz-transform: scale(1.617);
							-o-transform: scale(1.617);
							transform: scale(1.617);
						}
					}
				}

				@media screen and ( max-width: 768px ){
					&{
						width: auto;
						height: auto;
					}
				}
			}
		}
		.swiper-slide {
			// pointer-events: none;
			-webkit-transform-style: preserve-3d;
			-moz-transform-style: preserve-3d;
			-o-transform-style: preserve-3d;
			transform-style: preserve-3d;
			backface-visibility: hidden;width: 90%;box-sizing: border-box;

				&:after {
					content: " ";
					position: absolute;
					width: 100%;
					height: 10px;
					border-radius: 50%;
					left: 0;
					bottom: -50px;
					box-shadow: 0 30px 20px rgba(0, 0, 0, .3);
			}
		}
	}

	/* Dekor Icons */
	.dekor-stretch-rectangle-horizontal{
			width: 143px;
			height: 34px;
			right: 72px;
			top: 46px;
			background-image: url($stretch_rectangle_horizontal_ico);

			@media screen and ( max-width: 768px ){
				&{
					display: none;
				}
			}
	}
	.dekor-stretch-rectangle-vertical{
		width: 34px;
		height: 72px;
		right: 262px;
		top: 64px;
		background-image: url($stretch_rectangle_vertical_ico);
		
		@media screen and ( max-width: 768px ){
			&{
				display: none;
			}
		}
	}
	.dekor-small-star{
		width: 25px;
		height: 25px;
		left: -8px;
		top: 2px;
		background-image: url($small_star_ico);

		@media screen and ( max-width: 768px ){
			&{
				top: -76px;
			}
		}
	}
	.dekor-medium-ellipse{
		width: 53px;
		height: 52px;
		left: 11px;
		bottom: 74px;
		background-image: url($medium_ellipse_ico);

		@media screen and ( max-width: 768px ){
			&{
				left: -28px;
				bottom: 155px;
			}
		}
	}
	.dekor-bat{
		width: 116px;
		height: 116px;
		left: 95px;
		top: -70px;
		background-image: url($bat_character_ico);

		@media screen and ( max-width: 768px ){
			&{
				width: 55px;
				height: 55px;
				left: 5px;
				top: -30px;
			}
		}
	}
	.dekor-death-character{
		width: 90px;
		height: 90px;
		right: 55px;
		top: -70px;
		background-image: url($death_character_ico);

		@media screen and ( max-width: 768px ){
			&{
				width: 39px;
				height: 39px;
				right: 5px;
				top: -28px;
			}
		}
	}
	.dekor-small-star-thin{
		width: 29px;
		height: 29px;
		right: 126px;
		bottom: 90px;
		background-image: url($small_star_thin_ico);

		@media screen and ( max-width: 768px ){
			&{
				right: 20px;
				bottom: 200px;
			}
		}
	}
	/* //END// of dekor icons */

}
/* //END// of NFT-Collection Screen */

/* Gameplay Screen */
.gameplay-screen{
	position: relative;

	.bg-wrap{
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -2;

		.bg{
			position: absolute;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, #1C1C28 0%, rgba(28, 28, 40, 0) 77.78%), linear-gradient(360deg, #1C1C28 0%, rgba(28, 28, 40, 0) 77.78%), url($gameplay-screen-image);
			background-position: center 5%;
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

	.row{
		margin: 78px 0 230px 0;

		@media screen and ( max-width: 768px ){
			&{
				margin-top: 24px;
				margin-bottom: 169px;
			}
		}
	}

	.container{
		max-width: 1130px;
		padding-top: 152px;
		padding-right: 0;
		.gameplaySlider{
			padding-bottom: 60px;

			@media screen and ( max-width: 768px ){
				&{
					padding-bottom: 0;
				}
			}

			.item{
				img{
					@include border-radius(15px);
					overflow: hidden;
				}
				&.swiper-slide-active{
					-webkit-transform: scale(1.5);
					-moz-transform: scale(1.5);
					-o-transform: scale(1.5);
					transform: scale(1.5);
					z-index: 9;
					margin-top: 74px;

					@media screen and ( max-width: 768px ){
						&{
							-webkit-transform: scale(1);
							-moz-transform: scale(1);
							-o-transform: scale(1);
							transform: scale(1);
							margin-top: 0;
						}
					}
				}
				.content{
					position: absolute;
					@include flex;
					align-items: flex-end;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					@include border-radius(15px);
					background: linear-gradient(179.87deg, rgba(0, 0, 0, 0) 0.12%, #000000 112.66%);

					.title{
						font-family: $thirty-font;
						font-size: 10px;
						text-align: center;
						padding: 10px 23px;
						width: 100%;
					}
				}
			}
			.navigation-button{
				width: 56px;
				position: absolute;
				height: 56px;
				z-index: 9;
				left: 50%;
				margin-left: 261px;
				margin-top: -90px;
				-webkit-transform: translate(-50%, -50%);
				-moz-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				@include border-radius(100%);
				overflow: hidden;
				cursor: pointer;

				.bg{
					position: absolute;
					@include flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background: rgba(255, 255, 255, 0.2);
					backdrop-filter: blur(10px);

					&:after{
						content: '';
						position: absolute;
						width: 32px;
						height: 32px;
						background-image: url($arrow_right_ico);
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
					}
				}
			}
			.swiper-button-next{
				margin-left: 261px;
			}
			.swiper-button-prev{
				margin-left: -261px;

				.bg{
					&:after{
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-o-transform: rotate(180deg);
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	/* Dekor icons */
	.dekor-eye-and-eclipse-2{
		width: 80px;
		height: 90px;
		right: 42px;
		top: 60px;
		background-image: url($eye_and_eclipse_2_ico);

		@media screen and ( max-width: 768px ){
			&{
				background-image: url($eye_and_eclipse_3_ico);
				right: 20px;
				width: 60px;
				height: 60px;
				top: 84px;
			}
		}
	}
	.dekor-small-star-thin{
		width: 29px;
		height: 29px;
		left: 95px;
		top: 117px;
		background-image: url($small_star_thin_ico);
		
		@media screen and ( max-width: 768px ){
			&{
				left: 20px;
				top: 122px;
			}
		}
	}
	.dekor-stretch-rectangle-horizontal {
		width: 143px;
		height: 34px;
		left: 40px;
		bottom: 200px;
		background-image: url($stretch_rectangle_horizontal_ico);
		
		@media screen and ( max-width: 768px ){
			&{
				left: -88px;
				bottom: 73px;
				-webkit-transform: rotate(180deg);
				-moz-transform: rotate(180deg);
				-o-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}
	}
	.dekor-stretch-rectangle-vertical {
		width: 34px;
		height: 72px;
		left: 231px;
		bottom: 218px;
		background-image: url($stretch_rectangle_vertical_ico);

		@media screen and ( max-width: 768px ){
			&{
				left: 100px;
				bottom: 87px;
				transform: rotate(180deg);
				-webkit-transform: rotate(180deg);
				-moz-transform: rotate(180deg);
				-o-transform: rotate(180deg);
			}
		}
	}
	.dekor-small-star-thin-2 {
		width: 29px;
		height: 29px;
		right: 300px;
		bottom: 230px;
		background-image: url($small_star_thin_ico);

		@media screen and ( max-width: 768px ){
			&{
				display: none;
			}
		}
	}
	.dekor-ellipse-2 {
		width: 20px;
		height: 20px;
		left: 50%;
		bottom: 120px;
		-webkit-transform: translateX(200px);
		-moz-transform: translateX(200px);
		-o-transform: translateX(200px);
		transform: translateX(200px);
		background-image: url($small_ellipse_2_ico);

		@media screen and ( max-width: 768px ){
			&{
				left: unset;
				right: 34px;
				bottom: 124px;
				-webkit-transform: unset;
				-moz-transform: unset;
				-o-transform: unset;
				transform: unset;
			}
		}
	}
	/* //END// of dekor icons */
}
/* //END// of Gameplay Screen */

/* Tokenomics Screen */
.tokenomics-screen{
	position: relative;

	.container{
		max-width: 1300px;
		width: 100%;
		padding-top: 74px;

		@media screen and ( max-width: 768px ){
			&{
				padding-top: 91px;
				padding-left: 16px;
				padding-right: 16px;
			}
		}

		.contract{
			margin-top: 48px;
		}
	}

	.snippets{
		@include flex;
		flex-wrap: wrap;
		margin-top: 32px;

		@media screen and ( max-width: 768px ){
				flex-direction: column;
		}

		&.contract{
			margin-top: 80px;
			margin-bottom: 67px;
			justify-content: center;
						
			@media screen and ( max-width: 768px ){
				&{
					margin-top: 42px;
					margin-bottom: 120px;
				}
			}

			.snippet-item{
				@include flex;
				// width: 270px;
				flex-direction: row;
				align-items: center;
				margin: 0;
				padding: 0;
				flex: 0;

				@media screen and ( max-width: 768px ){
					&{
						flex-direction: column;
					}
				}

				>.inner{
					width: 100%;
					width: 270px;
					z-index: 1;
						
					@media screen and ( max-width: 768px ){
						&{
							width: 100%;
						}
					}

					figure{
						padding: 25px;
					}
					.content{
						padding: 25px;
						padding-top: 0;

						.title{
							font-size: 20px;
							line-height: 30px;
							font-weight: 400;
							
							h3{
								font-size: 20px;
								line-height: 30px;
								font-weight: 400;
							}
						}
					}
				
					&:before{
						background-color: #746AAD!important;
					}
					&:hover:before{
						background-color: $green-color!important;
					}
					&:hover{
						.bg-circle-light{
							background: $green-color!important;
						}
						.content{
							.title{
								color: $green-color;
								h3{
									color: $green-color;
								}
							}
						}
					}

					&:hover:after{
						content: "";
						position: absolute;
						background: #fff !important;
						bottom: -105px;
						filter: blur(70px);
						width: 128px;
						height: 128px;
						border-radius: 100%;
						right: -105px;
					}

					& > a{
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						z-index: 1;
					}
				}
				&:after{
					content: "";
					width: 90px;
					height: 60px;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
					margin-left: -15px;
					margin-right: -15px;
				}
						
				@media screen and ( max-width: 768px ){
					&:after{
						-webkit-transform: rotate(90deg);
						-moz-transform: rotate(90deg);
						-o-transform: rotate(90deg);
						transform: rotate(90deg);
					}
				}

				&:nth-child(1):after{
					background-image: url($green_bound_ico);
				}
				&:nth-child(2):after{
					background-image: url($violet_bound_ico);
				}
				&:nth-child(3):after{
					background-image: url($red_bound_ico);
				}
				&:nth-child(5){
					flex: 0;
					flex-direction: column;
				}
				&:nth-child(5):before{
					content: '';
					width: 100%;
					height: 60px;
					background: url($violet_left_top_bound_ico) no-repeat left, url($violet_right_top_bound_ico) no-repeat right;

					@media screen and ( max-width: 768px ){
						&{
							width: 90px;
							background-image: url($violet_bound_ico);
							-webkit-transform: rotate(90deg);
							-moz-transform: rotate(90deg);
							-o-transform: rotate(90deg);
							transform: rotate(90deg);
						}
					}
				}
				&:last-child:after{
					display: none;
				}
				.bg-circle-light{
					position: absolute;
					background: #746AAD!important;
					top: -40px;
					filter: blur(70px);
					width: 128px;
					height: 128px;
					border-radius: 100%;
					left: -58px;
				}

				
				&:nth-child(4){
					&:after{
						display: none;

						@media screen and ( max-width: 1300px ){
							&{
								display: block;
							}
						}
					}
				}

				&:nth-child(3){
					@media screen and ( max-width: 1300px ){
						&:after{
							display: none;
						}
					}
				}
				@media screen and ( max-width: 1300px ){
					&:nth-child(4){
						&:after{
							display: none;
						}
						flex-basis: 360px;
						flex-direction: column;
					}
					&:nth-child(4):before{
						content: '';
						width: 100%;
						height: 60px;
						background: url($violet_left_top_bound_ico) no-repeat left, url($violet_right_top_bound_ico) no-repeat right;
					}
					&:nth-child(5){
						flex-basis: 360px;
					}
				}
				@media screen and ( max-width: 970px ){
					&{
						flex-basis: 330px;
					}
					&:nth-child(1){
						flex-direction: column;
						&:after{
							-webkit-transform: rotate(90deg);
							-moz-transform: rotate(90deg);
							-o-transform: rotate(90deg);
							transform: rotate(90deg);
						}
					}
					&:nth-child(2){
						flex-direction: column;
						&:after{
							-webkit-transform: rotate(90deg);
							-moz-transform: rotate(90deg);
							-o-transform: rotate(90deg);
							transform: rotate(90deg);
						}
					}
					&:nth-child(3){
						
						&:after{
							display: block;
						}
					}
					&:nth-child(4){
						flex-basis: unset;
						&:before{
							display: none;
						}
					}
				}	
				@media screen and ( max-width: 768px ){
					&{
						flex-basis: unset!important;
					}
				}
			}
		}

		.snippet-item{
			@include flex;
			flex-direction: column;
			position: relative;

			>.inner{
				position: relative;
				@include border-radius(16px);
				height: 100%;
				overflow: hidden;

				&:before{
					position: relative;
					content: '';
					display: block;
					background-color: $dark-blue-color;
					height: 10px;
					z-index: -1;
				}
				.content{

					.title{
						position: relative;
						font-family: $thirty-font;
						@include flex;
						font-weight: 400;
						font-size: 24px;
						line-height: 24px;
						color: $white-blue
					}
					.description{
						padding: 18px 24px 24px 24px;
						font-weight: 400;
						font-size: 14px;
						line-height: 21px;
						color: $white-blue;

						p{
							padding: 18px 24px 24px 24px;
							font-weight: 400;
							font-size: 14px;
							line-height: 21px;
							color: $white-blue;
							padding: 0;

							&:last-child{
								margin-bottom: 0;
							}
						}
					}
					h3{
						position: relative;
						font-family: $thirty-font;
						@include flex;
						font-weight: 400;
						font-size: 24px;
						color: $white-blue;
						width: 100%;
						justify-content: center;
						flex-direction: column;
						text-align: center;
						margin-bottom: 0;
						line-height: 32px;
						padding: 0;
						
						@media screen and ( max-width: 768px ){
							&{
								font-size: 16px;
							}
						}

						>span{
							display: block;
							width: 100%;
							margin-top: 16px;
							text-transform: uppercase;
						
							@media screen and ( max-width: 768px ){
								&{
									margin-top: 0;
								}
							}

							&:first-child{
								margin-top: 0;
							}
						}
					}
				}
				figure{
					text-align: center;

					img{
						width: 80px;
						height: 80px;
					}
				}
			}

			.bg{
				position: absolute;
				width: calc(100% + 32px);
				height: 100%;
				left: -16px;
				right: -16px;
				top: 0;
				background: rgba(32, 32, 46, 0.8);
				@include border-radius(32px 32px 0 0);
				backdrop-filter: blur(15px);
				z-index: -1;
				margin-top: 5px;
			}

			&:nth-child(1){
				.bg-circle-light{
					position: absolute;
					background: $dark-blue-color;
					top: -40px;
					filter: blur(70px);
					width: 128px;
					height: 128px;
					border-radius: 100%;
					left: -58px;
				}
			}
			&:nth-child(2){
				.bg-circle-light{
					position: absolute;
					background: $primary-orange;
					top: -40px;
					filter: blur(70px);
					width: 128px;
					height: 128px;
					border-radius: 100%;
					left: -58px;
				}
			}
			&:nth-child(3){
				.bg-circle-light{
					position: absolute;
					background: $green-color;
					top: -40px;
					filter: blur(70px);
					width: 128px;
					height: 128px;
					border-radius: 100%;
					left: -58px;
				}
			}
		}

		&.coin-style{
			margin-left: -12px;
			margin-right: -12px;

			.snippet-item{

				&:before{
					position: relative;
					display: block;
					content: '';
					width: 98px;
					height: 98px;
					min-height: 98px;
					margin: 0 auto;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					margin-bottom: -49px;
				}
						
				@media screen and ( max-width: 768px ){
					&:before{
						width: 79px;
						height: 79px;
					}
				}

				&:nth-child(1){
					&:before{
						background-image: url($nft_coin);
					}
				}
				&:nth-child(2){
					&:before{
						background-image: url($binance_coin);
					}
				}
				&:nth-child(3){
					&:before{
						background-image: url($allcoin);
					}
				}

				>.inner{
					.content{
						padding: 88px 24px 48px 24px;
						
						@media screen and ( max-width: 768px ){
							&{
								padding: 54px 24px 40px 24px;
							}
						}
					}
				}

			}
		}
		.snippet-item{
			padding: 0 12px;

			@media screen and ( max-width: 768px ){
				&{
					padding: 0;
					margin-bottom: 25px;
				}
				&:nth-child(2){
					.dekor-warior-character{
						display: none;
					}
				}
				&:last-child{
					margin-bottom: 0;
				}
			}

			&:nth-child(even){
				>.inner{
					&:before{
						background-color: $primary-orange;
					}
				}
			}
			&:nth-child(3){
				>.inner{
					&:before{
						background-color: $green-color;
					}
				}
			}
		}
	}
	.container{
		@media screen and ( max-width: 768px ){
			&.contract{
				margin-top: 0;
				padding: 82px 0 0 0;

				.snippets{
					margin-top: 42px;
				}
			}
		}

		.contract{
			width: 100%;

			.snippets{
				justify-content: center;

				.snippet-item{
					max-width: 1050px;
					width: 100%;
					flex: 0 1 100%;
					margin: 0;
					margin-top: 20px;
	
					&:first-child{
						margin-top: 0;
					}

					&:before{
						display: none;
					}

					.title{
						justify-content: center;
						font-size: 24px;
						// max-width: 790px;
						line-height: 32px;
						// margin-left: auto;
						// margin-right: auto;
						overflow: hidden;

						&:after{
							content: '...';
							letter-spacing: -8px;
						}

						span{
							width: 100%;
							overflow: hidden;

							&:first-child{
								width: auto;
								padding-right: 34px;
							}
						}
						
						@media screen and ( max-width: 768px ){
							&{
								font-size: 11px;
							}
							&:after{
								content: '...';
								letter-spacing: -3px;
							}
						}
					}

					.content{
						@include flex;
						justify-content: center;
						padding: 5px 10px 10px 10px;
						
						@media screen and ( max-width: 768px ){
							&{
								padding: 5px;
							}
						}

						.copy_ico{
							display: block;
							width: 32px;
							min-width: 32px;
							height: 32px;
							background-image: url($copy_ico);
							background-color: transparent;
							margin-left: 16px;
							border: 0;
							padding: 0;
						
							@media screen and ( max-width: 768px ){
								&{
									/**/
								}
							}

							&:hover{
								background-image: url($copy_ico_green);
							}

							>svg{
								display: none;
							}

							.copied-ico{
								display: none;
							}

							&.copied{
								background-image: none;

								.copied-ico{
									display: block;

									>svg{
										display: block;
										fill: none;
										stroke: $green-color;
										stroke-width: 20;
										stroke-linecap: round;
										stroke-dasharray: 180;
										stroke-dashoffset: 180;
										animation: draw .25s ease forwards;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	/* Dekor icons */
	.dekor-stretch-rectangle-horizontal{
		width: 143px;
		height: 34px;
		right: 41px;
		top:73px;
		background-image: url($stretch_rectangle_horizontal_ico);

		@media screen and ( max-width: 768px ){
			&{
				right: -70px;
				top: 79px;
			}
		}
	}
	.dekor-stretch-rectangle-vertical{
		width: 34px;
		height: 74px;
		right: 231px;
		top: 90px;
		background-image: url($stretch_rectangle_vertical_ico);

		@media screen and ( max-width: 768px ){
			&{
				display: none;
			}
		}
	}
	.dekor-dower-chest{
		width: 64px;
		height: 64px;
		right: 102px;
		top: 26px;
		background-image: url($dower_chest_ico);

		@media screen and ( max-width: 768px ){
			&{
				width: 35px;
				height: 35px;
				right: 16px;
				top: 44px;
			}
		}
	}
	.dekor-small-star-thin{
		width: 29px;
		height: 29px;
		left: 300px;
		top: 112px;
		-webkit-transform: rotate(30deg);
		-moz-transform: rotate(30deg);
		-o-transform: rotate(30deg);
		transform: rotate(30deg);
		background-image: url($small_star_thin_ico);

		@media screen and ( max-width: 768px ){
			&{
				top: 53px;
				left: 20px;
			}
		}
	}
	.dekor-warior-character{
		width: 71px;
		height: 70px;
		left: 63px;
		top: -14px;
		background-image: url($warior_character_ico);
	}
	.dekor-medium-ellipse{
		width: 53px;
		height: 52px;
		left: -50px;
		bottom: 56px;
		background-image: url($medium_ellipse_ico);
						
		@media screen and ( max-width: 768px ){
			&{
				width: 26px;
				height: 26px;
				left: 39px;
				bottom: 116px;
				z-index: -1;
			}
		}
	}
	.dekor-eye{
		width: 82px;
		height: 82px;
		left: -42px;
		bottom: -44px;
		background-image: url($eye_ico);
						
		@media screen and ( max-width: 768px ){
			&{
				bottom: 22px;
			}
		}
	}
	.dekor-skeleton-character{
		width: 74px;
		height: 74px;
		right: 109px;
		top: -61px;
		background-image: url($skeleton_character_ico);
						
		@media screen and ( max-width: 768px ){
			&{
				width: 51px;
				height: 52px;
				top: -43px;
				right: 58px;
			}
		}
	}
	.dekor-businessman-character{
		width: 61px;
		height: 61px;
		right: 80px;
		top: -56px;
		background-image: url($businessman_character_ico);
						
		@media screen and ( max-width: 768px ){
			&{
				width: 42px;
				height: 42px;
				top: -39px;
				right: 37px;
			}
		}
	}
	.dekor-small-star-thin-2{
		width: 29px;
		height: 29px;
		right: 20px;
		bottom: 84px;
		background-image: url($small_star_thin_ico);
						
		@media screen and ( max-width: 768px ){
			&{
				bottom: 103px;
				right: -23px;
			}
		}
	}
	/* //END// of dekor icons */
}

/* Footer Screen */
.footer-screen{
	position: relative;

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		height: 900px;
		bottom: -180px;
		background: linear-gradient(180deg, #1C1C28 0%, rgba(28, 28, 40, 0) 77.78%), linear-gradient(360deg, #1C1C28 3%, rgba(28, 28, 40, 0) 77.78%), url($footer-screen-image);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
}
footer{
	position: relative;
	padding: 16px 40px 40px 40px;
	@include border-radius(16px 16px 0 0);
	overflow: hidden;
						
	@media screen and ( max-width: 768px ){
		&{
			padding: 40px 20px 32px 20px;
		}
		.container{
			padding: 0;
		}
	}

	.bg-overlay{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba(32, 32, 46, .6);
		backdrop-filter: blur(15px);
	}

	.container{
		max-width: 1300px;
		width: 100%;
	}
	.col-3{
		@include flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		&{
			>*:nth-child(2){
				.description{
					display: none;
				}
			}
			>*:nth-child(3){
				.powered{
					display: none;
				}
			}
		}
						
		@media screen and ( max-width: 768px ){
			&{
				flex-direction: column;

				>*{
					width: 100%;
				}
				>*:first-child{
					.policy{
						display: none;
					}
				}
				>*:nth-child(2){
					.powered{
						display: none;
					}
					.description{
						display: block;
						
						.policy{
							margin-top: 40px;
						}
					}
				}
				>*:nth-child(3){
					.powered{
						display: block;
						margin-top: 40px;
						font-size: 12px;
						line-height: 18px;
					}
				}
				figure{
					margin-top: 40px;
				}
			}
		}

		>*{
			flex: 1 1 33.3333%;
		}
	}
	.title{
		font-family: $thirty-font;
		font-size: 16px;
		line-height: 16px;
		font-weight: 400;
		text-align: center;
		margin-bottom: 8px;
	}
	.description{
		text-align: center;

		ol, li{
			text-align: center;
			list-style-position: inside;
			margin: 0;
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			color: #8d8d93;
		}
		.policy{
			display: block;
			font-family: $thirty-font;
			font-size: 16px;
			line-height: 16px;
			font-weight: 400;
			text-align: center;
			margin-bottom: 8px;
			margin-bottom: 0;
			margin-top: 40px;
			color: $white-color;

			&:hover{
				color: $white-color;
				text-decoration: underline;
			}
		}
	}
	figure{
		height: 150px;
		text-align: center;
		margin-bottom: 20px;
		
		img{
			height: 100%;
			image-rendering: pixelated;
		}
	}

	.powered{
		font-size: 16px;
		line-height: 28px;
		font-weight: 400;
		text-align: center;

		span{
			font-family: $thirty-font;
		}
	}

	.social_bar{
		@include flex;
		flex-wrap: wrap;
		justify-content: center;
						
		@media screen and ( max-width: 768px ){
			&{
				margin-top: 40px;
			}
		}

		.link{
			width: 35px;
			height: 35px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			margin: 0 10px;
			transition: all .2s ease-in-out;
			outline: none;
						
			@media screen and ( max-width: 768px ){
				&{
					margin: 0 6px;
				}
			}

			&:first-child{
				margin-left: 0;
			}

			&.telegram{
				background-image: url($telegram_ico);
			}
			&.etherscan{
				background-image: url($etherscan_ico);
			}
			&.twitter{
				background-image: url($twitter_ico);
			}
			&.bscscan{
				background-image: url($bscscan_ico);
			}
			&.github{
				background-image: url($github_ico);
			}
			&.medium{
				background-image: url($medium_ico);
			}

			&:last-child{
				margin-right: 0;
			}

			&:hover{
				-webkit-transform: scale(1.15);
				-moz-transform: scale(1.15);
				-o-transform: scale(1.15);
				transform: scale(1.15);
			}
		}
	}
}

// Keyframes
@keyframes draw {
	to {
	  stroke-dashoffset: 0;
	}
}

/* //END// of Tokenomics Screen */


/* Page -> Privacy Policy */
.privacy-screen{
	
	.container{
		max-width: 1265px;
		margin-top: 34px;

		.content{
			margin: 33px 0 40px 0;
		}
	}

	.title{
		margin-top: 0;

		>*{
			font-family: $thirty-font;
			font-size: 32px;
			line-height: 40px;
			font-weight: 400;
			color: $white-color;
			margin-bottom: 0;
		}
	}

	.title-h2{
		margin-top: 20px;

		>*{
			font-family: $secondary-font;
		}
	}

	p{
		font-size: 12px;
		line-height: 21px;
		color: $white-blue;
		margin-bottom: 0;
	}
	ul, ol{
		margin-left: 0;
		margin-bottom: 0;

		li{
			font-size: 12px;
			line-height: 21px;
			color: $white-blue;
			margin-left: 12px;
		}
	}

	a{
		color: $white-blue;

		&:hover{
			color: $white-blue;
		}
	}

	.no-styled{
		margin: 0;
		list-style: none;

		li{
			margin-left: 0;
		}
	}
}
/* //END// of page -> Privacy Policy */



//////////////////////////////////////////////////////////////////////////////////////////////

.gameplay_slider{
	position: relative;
	@include flex;
	justify-content: center;

	.wrap{
		position: relative;
		max-width: 1265px;
		width: 100%;
		height: 332px;
		@include flex;
		justify-content: center;
						
		@media screen and ( max-width: 768px ){
			&{
				height: 180px;
				justify-content: flex-start;
			}
		}

		.item{
			position: absolute;
			width: 40%;
			// min-height: 245px;
			height: 245px;
			will-change: transform;
			@include border-radius(15px);
			box-shadow: 0px 0px 20px 0px rgb(0 0 0);
			transition: all .3s ease-in;
			z-index: 1;
			overflow: hidden;
			cursor: pointer;
			-webkit-transform: translateZ(-1000px);
			-moz-transform: translateZ(-1000px);
			-o-transform: translateZ(-1000px);
			transform: translateZ(-1000px);
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
						
			@media screen and ( max-width: 768px ){
				&{
					position: relative;
					width: 85%;
					min-width: 85%;
					height: 180px;
					-webkit-transform: translate(0%, 0) scale(1)!important;
					-moz-transform: translate(0%, 0) scale(1)!important;
					-o-transform: translate(0%, 0) scale(1)!important;
					transform: translate(0%, 0) scale(1)!important;
					// margin-right: 24px;
				}
			}

			&.previous-slide{
				z-index: 2;
				-webkit-transform: translate(-75%, 0);
				-moz-transform: translate(-75%, 0);
				-o-transform: translate(-75%, 0);
				transform: translate(-75%, 0);
			}

			&.active-slide{
				z-index: 3;
				-webkit-transform: translate(0%, 0) scale(1.3) translateZ(1px);
				-moz-transform: translate(0%, 0) scale(1.3) translateZ(1px);
				-o-transform: translate(0%, 0) scale(1.3) translateZ(1px);
				transform: translate(0%, 0) scale(1.3) translateZ(1px);
						
				@media screen and ( max-width: 768px ){
					&{
						-webkit-transform: translate(0%, 0) scale(1) translateZ(1px);
						-moz-transform: translate(0%, 0) scale(1) translateZ(1px);
						-o-transform: translate(0%, 0) scale(1) translateZ(1px);
						transform: translate(0%, 0) scale(1) translateZ(1px);
					}
				}

				.content{
					opacity: 1;
				}
			}

			&.next-slide{
				z-index: 2;
				-webkit-transform: translate(75%, 0);
				-moz-transform: translate(75%, 0);
				-o-transform: translate(75%, 0);
				transform: translate(75%, 0);
			}

			.content{
				position: absolute;
				z-index: 1;
				width: 100%;
				bottom: 0;
				text-align: center;
				font-size: 12px;
				line-height: 20px;
				font-weight: 400;
				font-family: $thirty-font;
				opacity: .5;

				>*{
					display: block;
					margin-bottom: 18px;
				}
			}

			&:after{
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 1px;
				left: 0;
				background: linear-gradient(179.87deg, rgba(0, 0, 0, 0) 0.12%, #000000 112.66%);
				
			}

			img{ // deprecated
				object-fit: cover;
				height: 100%;
			}
			
			.image{
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				left: 0;
				top: 0;
			}

			&.video{
				&:after{
					background: unset;
				}
			}

			.video{
				position: relative;
				height: 100%;

				video{
					position: absolute;
					width: 100%;
					left: 50%;
					top: 50%;
					-webkit-transform: translate(-50%, -50%);
					-moz-transform: translate(-50%, -50%);
					-o-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
						
					@media screen and ( max-width: 768px ){
						&{
							-webkit-transform: translate(-50%, -50%) scale(1.2);
							-moz-transform: translate(-50%, -50%) scale(1.2);
							-o-transform: translate(-50%, -50%) scale(1.2);
							transform: translate(-50%, -50%) scale(1.2);
						}
					}
				}
			}
		}
	}

	&.GPlookUpEffect{
		.item{
			&.previous-slide{
				z-index: 2;
				-webkit-transform: translate(-75%, 60px) perspective(300px) rotateX(-3deg);
				-moz-transform: translate(-75%, 60px) perspective(300px) rotateX(-3deg);
				-o-transform: translate(-75%, 60px) perspective(300px) rotateX(-3deg);
				transform: translate(-75%, 60px) perspective(300px) rotateX(-3deg);
			}

			&.active-slide{
				z-index: 3;
				-webkit-transform: translate(0%, 90px) perspective(300px) rotateX(-3deg);
				-moz-transform: translate(0%, 90px) perspective(300px) rotateX(-3deg);
				-o-transform: translate(0%, 90px) perspective(300px) rotateX(-3deg);
				transform: translate(0%, 90px) perspective(300px) rotateX(-3deg);
			}

			&.next-slide{
				z-index: 2;
				-webkit-transform: translate(75%, 60px) perspective(300px) rotateX(-3deg);
				-moz-transform: translate(75%, 60px) perspective(300px) rotateX(-3deg);
				-o-transform: translate(75%, 60px) perspective(300px) rotateX(-3deg);
				transform: translate(75%, 60px) perspective(300px) rotateX(-3deg);
			}
		}

		.navigation-button{
			&.previous{
				left: 27.3%;
				top: 53%;
			}

			&.next{
				right: 27.3%;
				top: 53%;
			}
		}
	}
	.navigation-button{
		position: absolute;
		width: 56px;
		height: 56px;
		z-index: 4;
		@include border-radius(100%);
		overflow: hidden;
		cursor: pointer;
		transition: all .3s ease-in;
		-webkit-transform: translateZ(1000px);
		-moz-transform: translateZ(1000px);
		-o-transform: translateZ(1000px);
		transform: translateZ(1000px);
						
		@media screen and ( max-width: 768px ){
			&{
				display: none;
			}
		}

		.bg{
			position: absolute;
			@include flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: rgba(255, 255, 255, 0.2);
			backdrop-filter: blur(10px);

			&:after{
				content: "";
				position: absolute;
				width: 32px;
				height: 32px;
				background-image: url($arrow_right_ico);
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}

		&.previous{
			.bg{
				&:after{
					-webkit-transform: rotate(180deg);
					-moz-transform: rotate(180deg);
					-o-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
		}

		&:hover{
			-webkit-transform: scale(1.1) translateZ(1000px);
			-moz-transform: scale(1.1) translateZ(1000px);
			-o-transform: scale(1.1) translateZ(1000px);
			transform: scale(1.1) translateZ(1000px);
		}

		&.previous{
			left: 21.66%;
			top: 26%;
		}

		&.next{
			right: 21.66%;
			top: 26%;
		}
	}
}