/* Include "Poppins" Font */
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-Black.otf');
   font-weight: 900;
   font-style: normal;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-BlackItalic.otf');
   font-weight: 900;
   font-style: italic;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-Bold.otf');
   font-weight: 700;
   font-style: normal;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
   font-weight: 700;
   font-style: italic;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-ExtraBold.otf');
   font-weight: 800;
   font-style: normal;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-ExtraBoldItalic.otf');
   font-weight: 800;
   font-style: italic;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-ExtraLight.otf');
   font-weight: 200;
   font-style: normal;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-ExtraLightItalic.otf');
   font-weight: 200;
   font-style: italic;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-Italic.otf');
   font-weight: 400;
   font-style: italic;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-Light.otf');
   font-weight: 300;
   font-style: normal;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-LightItalic.otf');
   font-weight: 300;
   font-style: italic;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-Medium.otf');
   font-weight: 500;
   font-style: normal;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-MediumItalic.otf');
   font-weight: 500;
   font-style: italic;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-Regular.otf');
   font-weight: 400;
   font-style: normal;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
   font-weight: 600;
   font-style: normal;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-SemiBoldItalic.otf');
   font-weight: 600;
   font-style: italic;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-Thin.otf');
   font-weight: 100;
   font-style: normal;
}
@font-face {
   font-family: 'Poppins';
      src: url('../fonts/poppins/Poppins-ThinItalic.otf');
   font-weight: 100;
   font-style: italic;
}

/* Include "Press Start K" font */
@font-face {
   font-family: 'PrStart';
      src: url('../fonts/prstart/PrStart.ttf');
   font-weight: 400;
   font-style: normal;
}
@font-face {
   font-family: 'prstartk';
      src: url('../fonts/prstart/prstartk.ttf');
   font-weight: 400;
   font-style: normal;
}