@charset "UTF-8";
address, blockquote, body, dd, dl, dt, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, html, iframe, legend, li, ol, p, pre, textarea, ul {
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}
html {
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow-x: hidden;

    body{
        margin: 0;
        overflow: hidden;
    }
}
main, nav {
    display: block;
}
progress {
    display: inline-block;
    vertical-align: baseline;
}
a {
    background-color: transparent;
}
a:active {
    outline: 0;
}
a, a:focus, a:hover, a:visited {
    text-decoration: none;
}
abbr[title] {
    border-bottom: 1px dotted;
}
b,
strong {
    font-weight: 700;
}
dfn {
    font-style: italic;
}
mark {
    background: #ff0;
    color: #000;
}
small {
    font-size: 80%;
}
sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
img {
    border: 0;
}
svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 0;
}
hr {
    box-sizing: content-box;
    height: 0;
}
pre {
    overflow: auto;
}
code, kbd, pre, samp {
    font-size: 1em;
}
button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}
button {
    overflow: visible;
}
button, select {
    text-transform: none;
}
button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled], html input[disabled] {
    cursor: default;
}
button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input {
    line-height: normal;
}
input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
}
#comments .submit, .search .search-submit {
    padding: 10px 15px;
    border-radius: 2px;
    line-height: 1.85714285714286;
    border: 0;
}
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
fieldset {
    margin: 0 0;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    border: 0;
    padding: 0;
}
fieldset legend {
    margin-bottom: 1.5em;
    padding: 0 0.5em;
}
textarea {
    overflow: auto;
}
optgroup {
    font-weight: 700;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
td, th {
    padding: 0;
} /*!
 * Bootstrap v4.0.0-alpha.2 (https://getbootstrap.com)
 */
h1, h2, h3, h4, h5, h6 {
    clear: both;
}
h1 {
    color: #808285;
    font-size: 2em;
    line-height: 1.2;
}
h2 {
    color: #808285;
    font-size: 1.7em;
    line-height: 1.3;
}
h3 {
    color: #808285;
    font-size: 1.5em;
    line-height: 1.4;
}
h4 {
    color: #808285;
    line-height: 1.5;
    font-size: 1.3em;
}
h5 {
    color: #808285;
    line-height: 1.6;
    font-size: 1.2em;
}
h6 {
    color: #808285;
    line-height: 1.7;
    font-size: 1.1em;
}
html {
    box-sizing: border-box;
}
*, :after, :before {
    box-sizing: inherit;
}
body {
    color: #808285;
    background: #fff;
    font-style: normal;
}
ol, ul {
    margin: 0 0 1.5em 3em;
}
ul {
    list-style: disc;
}
ol {
    list-style: decimal;
}
li > ol, li > ul {
    margin-bottom: 0;
    margin-left: 1.5em;
}
dt {
    font-weight: 700;
}
dd {
    margin: 0 1.5em 1.5em;
}
b, strong {
    font-weight: 700;
}
cite, dfn, em, i {
    font-style: italic;
}
blockquote, q {
    quotes: "" "";
}
blockquote:after, blockquote:before, q:after, q:before {
    content: "";
}
blockquote {
    padding: 20px;
    font-size: 1.2em;
    font-style: italic;
    margin: 0 0 1.5em;
    position: relative;
}
address {
    margin: 0 0 1.5em;
}
abbr, acronym {
    border-bottom: 1px dotted #666;
    cursor: help;
}
pre {
    background: #eee;
    font-family: "Courier 10 Pitch", Courier, monospace;
    margin-bottom: 1.6em;
    overflow: auto;
    max-width: 100%;
    padding: 1.6em;
}
code, kbd, tt, var {
    font: 15px Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}
img {
    height: auto;
    max-width: 100%;
}
hr {
    background-color: #ccc;
    border: 0;
    height: 1px;
    margin-bottom: 1.5em;
}
.button, button, input, select, textarea {
    color: #808285;
    font-weight: 400;
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
}
button, input {
    line-height: normal;
}
big {
    font-size: 125%;
}
ins, mark {
    background: 0 0;
    text-decoration: none;
}
table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 1.5em;
    width: 100%;
}
th {
    font-weight: 700;
}
td, th {
    padding: 8px;
    border-width: 0 1px 1px 0;
}
.button, button, input[type="button"], input[type="reset"], input[type="submit"] {
    border: 1px solid;
    border-radius: 2px;
    background: #e6e6e6;
    padding: 0.6em 1em 0.4em;
    color: #fff;
}
.button:focus, .button:hover, button:focus, button:hover, input[type="button"]:focus, input[type="button"]:hover, input[type="reset"]:focus, input[type="reset"]:hover, input[type="submit"]:focus, input[type="submit"]:hover {
    color: #fff;
}
.button:active, .button:focus, button:active, button:focus, input[type="button"]:active, input[type="button"]:focus, input[type="reset"]:active, input[type="reset"]:focus, input[type="submit"]:active, input[type="submit"]:focus {
    outline: 0;
}
input[type="email"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], textarea {
    color: #666;
    border-radius: 2px;
    -webkit-appearance: none;
}
input[type="email"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="url"]:focus, textarea:focus {
    color: #111;
}
textarea {
    padding-left: 3px;
    width: 100%;
}
a {
    color: #4169e1;
}
a:focus, a:hover {
    color: #191970;
}
a:focus {
    outline: thin dotted;
}
a:hover {
    outline: 0;
}
.alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
}
.alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
}
.aligncenter {
    clear: both;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
embed, iframe, object {
    max-width: 100%;
}
::selection {
    color: #fff;
    background: #0274be;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body{
    position: relative;
}
a, a:focus {
    text-decoration: none;
}
a {
    transition: all 0.2s linear;
}
.capitalize {
    text-transform: uppercase;
}
img {
    vertical-align: middle;
}
h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
}
p {
    margin-bottom: 1.75em;
}
blockquote {
    margin: 1.5em 1em 1.5em 3em;
    font-size: 1.1em;
    line-height: inherit;
    position: relative;
}
.button, input[type="button"], input[type="submit"] {
    border-radius: 0;
    padding: 18px 30px;
    border: 0;
    box-shadow: none;
    text-shadow: none;
}
.button:hover, input[type="button"]:hover, input[type="submit"]:hover {
    box-shadow: none;
}
.button:active, .button:focus, input[type="button"]:active, input[type="button"]:focus, input[type="submit"]:active, input[type="submit"]:focus {
    box-shadow: none;
}
input, select {
    line-height: 1;
}
body, button, input[type="button"], input[type="submit"], textarea {
    line-height: 1.85714285714286;
}
.hidden {
    display: none !important;
}
body {
    background-color: #fff;
}

/* ScrollBar */
/* width */
::-webkit-scrollbar {
    width: 5px;
  }
/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #FF8616;
}

/* Selection color */
::-moz-selection{
    color: #FF8616;
}
::selection{
    color: #FF8616;
    background-color: initial;
}